import React from 'react'
import Index from '../../../Assets/Images/Index'

import "./TestimonialCard.css"

export default function TestimonialCard({personImg, name, position, review}) {
    return (
        <div className='testimonialCard'>
            <div className='testimonialCard__top d-flex justify-content-between'>
                <div className='testimonialCard__left d-flex align-items-center'>
                    <div className='testimonialCard_avatar'><img src={personImg} alt="" /></div>
                    <div className='testimonialCard__name'>
                        <div className="h5 mb-0">{name}</div>
                        <p className="textBlack mb-0">{position}</p>
                    </div>
                </div>
                <div className='testimonialCard__right'><img src={Index.quotes} alt="" /></div>
            </div>
            <div className="testimonialCard__cntnt h5 fontRegular">{review}</div>
        </div>
    )
}
