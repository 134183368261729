import React from 'react'
import Index from '../../../../Assets/Images/Index'
import BannerContent from '../../../Common/BannerContent/BannerContent'
import TwoCol from '../../../Common/TwoCol/TwoCol'
import { signUpUrl } from '../../../../Container/App'
import SellingBox from '../../../Common/SellingBox/SellingBox'
import "../Sell.css"
export default function Facebook() {


  const createFbStore = [
    {
      img: Index.createFbStore,
      title: "Create your  Facebook store with Shopyxa ",
      content: "Easily add, promote, and sell products, or manage all your  Facebook advertising in just a few clicks — right from your Shopyxa dashboard.",
      btnText: "Start Free Trial",
    }
  ]
  const facebookAdvertisement = [
    {
      img: Index.facebookAdvertisement,
      title: "Facebook  Advertising turns easy with Shopyxa",
      content: "Convert window shoppers to paying customers with  Facebook remarketing. With a few clicks, we’ll create hyper  targeted ads to remind your customers about products they  considered but didn’t buy.",
      btnText: "Start Free Trial",
    }
  ]

  const facebookConnectS = [
    {
      img: Index.facebookConnectS,
      title: "Connect your  Facebook store to Instagram ",
      content: "Did we mention creating your Facebook store is the first step to selling on Instagram? When you’re ready to add Shoppable tags to your Instagram page, simply use your Shopyxa Facebook integration to connect your Facebook store to your Instagram Business Account.",
      btnText: "Start Free Trial",
    }
  ]
  const tagProdFb = [
    {
      img: Index.tagProdFbImg,
      title: "Tag your products in posts and stories",
      content: "Make your posts and stories shoppable with product tags for your Facebook store. If your followers spot a product they like in one of your posts and click the tag, they’ll be automatically transported to your checkout page to complete their purchase.",
      btnText: "Start Free Trial",
    }
  ]
  return (
    <main>
      <BannerContent
        bannerClass="sellFacebookBanner"
        title="Start your  Facebook Store in minutes"
        cntnt="Now it's easier than ever to set up  and manage a Facebook Store right from your Shopyxa dashboard. "
        img1={Index.sellFBBanner}
        btnText="Start Free Trial"
      />


      <section className='twocolSect commonPadding'>
        <div className="container">
          {createFbStore.map((data, indx) => {
            return (
              <TwoCol
                key={indx}
                img={data.img}
                title={data.title}
                content={data.content}
                btnText={data.btnText}
              />
            )
          })}
        </div>
      </section>
      <section className='twocolSect commonPadding'>
        <div className="container">
          {facebookAdvertisement.map((data, indx) => {
            return (
              <TwoCol
                imgRight
                key={indx}
                img={data.img}
                title={data.title}
                content={data.content}
                btnText={data.btnText}
              />
            )
          })}
        </div>
      </section>
      <section className='twocolSect commonPadding'>
        <div className="container">
          {facebookConnectS.map((data, indx) => {
            return (
              <TwoCol
                key={indx}
                img={data.img}
                title={data.title}
                content={data.content}
                btnText={data.btnText}
              />
            )
          })}
        </div>
      </section>
      <section className='twocolSect commonPadding'>
        <div className="container">
          {tagProdFb.map((data, indx) => {
            return (
              <TwoCol
                imgRight
                key={indx}
                img={data.img}
                title={data.title}
                content={data.content}
                btnText={data.btnText}
              />
            )
          })}
        </div>
      </section> 
      <SellingBox
        title="Start a Facebook Store in minutes"
        btnText="Start Free Trial"
        cntntPos="center" />
    </main>
  )
}
