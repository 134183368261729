import React from 'react'
import "./IconBox.css"
import Fade from 'react-reveal/Fade';
export default function IconBox({ data }) {
    return (
        <>
            {data.map((boxdata, indx) => {
                return (
                    <Fade key={indx} delay={indx*200}>
                        <div className="iconBox">
                            <div className="iconBox__img">
                                <img src={boxdata.img} alt="" />
                            </div>
                            <div className='h5'>{boxdata.title}</div>
                            <p>{boxdata.content}</p>

                        </div>
                    </Fade>
                )
            })}

        </>
    )
}
