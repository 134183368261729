import React from 'react'
import { Link } from 'react-router-dom';
import Index from '../../../Assets/Images/Index'
import { signUpUrl } from '../../../Container/App';
import SellingBox from '../../Common/SellingBox/SellingBox';
import "./Learn.css"


export default function Learn() {
  
  const learnBlogList = [

    {
      img: Index.learnBlog1,
      title: "Get Ready for Black Friday and Cyber Monday",
      author: "James All",
      date: "Oct 28, 2022",
    },
    {
      img: Index.learnBlog2,
      title: "Get Ready for Black Friday and Cyber Monday",
      author: "James All",
      date: "Oct 28, 2022",
    },
    {
      img: Index.learnBlog3,
      title: "Get Ready for Black Friday and Cyber Monday",
      author: "James All",
      date: "Oct 28, 2022",
    },
    {
      img: Index.learnBlog4,
      title: "Get Ready for Black Friday and Cyber Monday",
      author: "James All",
      date: "Oct 28, 2022",
    },
    {
      img: Index.learnBlog5,
      title: "Get Ready for Black Friday and Cyber Monday",
      author: "James All",
      date: "Oct 28, 2022",
    },
    {
      img: Index.learnBlog6,
      title: "Get Ready for Black Friday and Cyber Monday",
      author: "James All",
      date: "Oct 28, 2022",
    },
    {
      img: Index.learnBlog7,
      title: "Get Ready for Black Friday and Cyber Monday",
      author: "James All",
      date: "Oct 28, 2022",
    },
    {
      img: Index.learnBlog8,
      title: "Get Ready for Black Friday and Cyber Monday",
      author: "James All",
      date: "Oct 28, 2022",
    },
    {
      img: Index.learnBlog9,
      title: "Get Ready for Black Friday and Cyber Monday",
      author: "James All",
      date: "Oct 28, 2022",
    },
    {
      img: Index.learnBlog10,
      title: "Get Ready for Black Friday and Cyber Monday",
      author: "James All",
      date: "Oct 28, 2022",
    },
    {
      img: Index.learnBlog11,
      title: "Get Ready for Black Friday and Cyber Monday",
      author: "James All",
      date: "Oct 28, 2022",
    },

  ]

  const recomendedList = [
    {
      title: "Save Time and Money with Lightspeed Payments",
      link: "/learn/detail",
    },
    {
      title: "Best Practices For Ecommerce Shipping During The Holiday",
      link: "/learn/detail",
    },
    {
      title: "A Foolproof Advertising Strategy for the Holiday Season",
      link: "/learn/detail",
    },
    {
      title: "Best Practices For Ecommerce Shipping During ",
      link: "/learn/detail",
    },
    {
      title: "Advertising Smart on Facebook and Google with Kliken",
      link: "/learn/detail",
    },

  ]

  return (
    <main>
      <section className='greyBg commonPadding'>
        <div className="container">
          <div className="commonHeading text-center mb-0">
            <div className="h1 mb-0">Shopyxa Blog</div>
          </div>
        </div>
      </section>
      <section className='commonPadding'>
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-xl-9">
              <div className="LearnGrid">
                {learnBlogList.map((data, indx) => {
                  return (
                    <Link to="/learn/detail" className="LearnBox" key={indx}>
                      <div className="LearnBox__img">
                        <img src={data.img} alt="" />
                      </div>
                      <div className="LearnBox__cntnt">
                        <div className="LearnBox__detail">
                          <span className="authorby textBlack me-1">By</span>
                          <span className="author primaryClr me-1">{data.author}</span>
                          <span className="date">{data.date}</span>
                        </div>
                        <div className="h5">{data.title}</div>
                      </div>
                    </Link>
                  )
                })}

              </div>
            </div>
            <div className="col-md-4 col-xl-3 hideonMobile">
              <div className="subscribeBox__grp mb-3">
                <input type="search" className="form-control" placeholder="Search" />
                <button className="subscribeBox__button" type="button" id="button-addon2">
                  <img src={Index.search} alt="" />
                </button>
              </div>
              <div className="learnRecomendBox">
                <div className="learnRecomendBox fontBold h6">Recommended </div>
                <ul>
                  {recomendedList.map((data, indx) => {
                    return (
                      <li key={indx}>
                        <Link to={data.link}>{data.title}</Link>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>      
      <SellingBox
        title="Start Selling Online for Free"
        btnText="Start Free Trial"
        cntntPos="center" />
    </main>
  )
}
