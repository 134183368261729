import React from 'react'
import Accordion from 'react-bootstrap/Accordion';
import "./Faq.css"


export default function Faq() {
 
  const questionAnswer = [
    {
      question: "What is Shopyxa?",
      answer: "Shopyxa is a one-stop destination ecommerce platform to launch your business online from scratch or bring your established brick-and-mortar store online.",
    },
    {
      question: "How Shopyxa Works? ",
      answer: " After signing up, you can create your personalized store for selling your products online in India. You can manage product orders, inventory, payments, shipping, and all essential data insights. In other words, you can manage all the complex business operations at one central point in a simplified way while sitting anywhere in the world.",
    },
    {
      question: "What can I sell on Shopyxa?",
      answer: "You can sell any product or service in compliance with our terms and conditions. Also, your store products should come under the legality of the country's rules/regulations.",
    },
    {
      question: "Shopyxa is available in which countries?",
      answer: "You can sell your products and services across India through your Shopyxa store.",
    },
    {
      question: "What are the benefits of Shopyxa?",
      answer: "Shopyxa is a fully functional e-commerce platform that helps you launch your online business with the least effort and in the shortest amount of time. Customized theme designs have the potential to attract an audience and increase your sales. Additionally, automating repetitive tasks gives you more time to concentrate on other activities that will help you build your brand more successfully.  ",
    },
    {
      question: "What is the cost of using Shopyxa?",
      answer: "You can start with a 15 days trial offer without the need for credit cards. After this, you can continue the services with monthly subscription plans for advanced features and functionalities.",
    },
    {
      question: "How do I own a domain name with Shopyxa?",
      answer: "You can use your domain name with Shopyxa. You can buy a domain from us, or if you have an existing domain, you can connect it to your Shopyxa store.",
    },
    {
      question: "Do I need the help of the designers and developers to start selling on Shopyxa?",
      answer: "When you are just starting off your business, there is no need for knowledge of special technicalities. However, if you want to use advanced functionality and a high level of customization in your Shoyxa store, then you can reach our experts for customer support. We will solve your issues as soon as possible.",
    },
    {
      question: "What are the different payment modes supported by Shopyxa?",
      answer: "We provide you with different payment modes for online payments like Stripe and Razorpay and you can also enable cash on delivery.",
    },
    {
      question: "On which channels can I sell my products?",
      answer: "You can sell your products on your online store, and we are coming up with new features to sell through multiple channels like Facebook, Instagram, Google, etc. very soon.",
    },
    {
      question: "What about the product shipping?",
      answer: "You don't need to bother about the shipping part. You can use a third-party service to ship your orders.",
    },
    {
      question: "What is the merchant’s account?",
      answer: "After the authorization and processing of your customers' payments, your money is transferred into a merchant account.",
    },
    {
      question: "Can I get customer support to resolve the issues when creating my Shopyxa store?",
      answer: "Yes, our Shopyxa experts are available around the clock to solve your queries and issues.",
    },
   
  
  ]

  return (
    <main>
 
      <section className='commonPadding'>
        <div className="container">
          <div className="commonHeading text-center">
            <div className="h1">Frequently asked questions</div>
          </div>
          <Accordion defaultActiveKey="0">
            {questionAnswer.map((data, indx) => {
              return (
                <Accordion.Item eventKey={indx} key={indx}>
                  <Accordion.Header>{data.question}</Accordion.Header>
                  <Accordion.Body>{data.answer}</Accordion.Body>
                </Accordion.Item>
              )
            })}
          </Accordion>
          {/* <div className="loadingText d-flex justify-content-center align-items-center">
            <span>Loading </span>  <div className="dot-pulse"></div>
          </div> */}
        </div>
      </section>
 
    </main>
  )
}
