export default {
    logo: require("./logo.svg").default,
    logoWhite: require("./logoWhite.svg").default,
    // home page
    headerbanner1: require("./headerbanner1.png"),
    headerbanner2: require("./headerbanner2.png"),
    freeonlinestore: require("./freeonlinestore.png"),
    sellanywhere: require("./sellanywhere.png"),
    growfaster: require("./growfaster.png"),

    facebookBox: require("./facebookBox.png"),
    instagramBox: require("./instagramBox.png"),
    googleBox: require("./googleBox.png"),

    facebook: require("./bxl-facebook-circleFtr.svg").default,
    twitter: require("./bxl-twitterFtr.svg").default,
    instagram: require("./bxl-instagramFtr.svg").default,
    youtube: require("./bxl-youtubeFtr.svg").default,

    phone_icon: require("./phone_icon.svg").default,
    email_icon: require("./email_icon.svg").default,
    map_icon: require("./map_icon.svg").default,


    bulb: require("./bulb.png"),
    laptop: require("./laptop.png"),
    mail: require("./mail.png"),
    mobilecart: require("./mobilecart.png"),
    card: require("./card.png"),
    chart: require("./chart.png"),
    quotes: require("./quotes.png"),

    kavitaKausal: require("./kavitaKausal.jpg"),
    pawasMahajan: require("./pawasMahajan.jpg"),
    judieLastina: require("./judieLastina.jpg"),

    bxl_facebook_circle: require("./bxl_facebook_circle.svg").default,
    bxl_instagram: require("./bxl_instagram.svg").default,
    google_shopping: require("./google_shopping.svg").default,
    envelope: require("./envelope.svg").default,

    // home page

    // manage
    manageBanner: require("./manageBanner.png"),
    manageAnywhere: require("./manageAnywhere.png"),
    managefastSecure: require("./managefastSecure.png"),
    manageShipping: require("./manageShipping.png"),

    // manage
    // sell
    sellBanner: require("./sellBanner.png"),
    sellBanner2: require("./sellBanner2.png"),
    globePng: require("./globePng.png"),
    globeWhite: require("./globe-white.png"),
    sellonwebsite: require("./sellonwebsite.png"),
    sellonSocial: require("./sellonSocial.png"),
    sellonMarket: require("./sellonMarket.png"),
    startSelling: require("./startSelling.png"),

    // facebook
    sellFBBanner: require("./sellFBBanner.png"),
    createFbStore: require("./createFbStore.png"),
    facebookAdvertisement: require("./facebookAdvertisement.png"),
    facebookConnectS: require("./facebookConnectS.png"),
    tagProdFbImg: require("./tagProdFbImg.png"),

    // instagram
    sellInstaBanner: require("./sellInstaBanner.png"),
    sellIntaBox1: require("./sellIntaBox1.png"),
    sellIntaBox2: require("./sellIntaBox2.png"),
    sellIntaBox3: require("./sellIntaBox3.png"),
    startSellingwithInsta: require("./startSellingwithInsta.png"),
    // sell

    // learn
    learnBlog1: require("./learn/blog1.jpg"),
    learnBlog2: require("./learn/blog2.jpg"),
    learnBlog3: require("./learn/blog3.jpg"),
    learnBlog4: require("./learn/blog4.jpg"),
    learnBlog5: require("./learn/blog5.jpg"),
    learnBlog6: require("./learn/blog6.jpg"),
    learnBlog7: require("./learn/blog7.jpg"),
    learnBlog8: require("./learn/blog8.jpg"),
    learnBlog9: require("./learn/blog9.jpg"),
    learnBlog10: require("./learn/blog10.jpg"),
    learnBlog11: require("./learn/blog11.jpg"),
    search: require("./bx-search.svg").default,
    
    // learnDetail
    sqrlinkedin: require("./sqrlinkedin.svg").default,
    sqrtwitter: require("./sqrtwitter.svg").default,
    sqrenvelope: require("./sqrenvelope.svg").default,
    sqrfacebook: require("./sqrfacebook.svg").default,
    learnBlogInr: require("./learn/learnBlogInr.jpg"),
    authorImg: require("./learn/authorImg.jpg"),
    // learnDetail

    // learn
}