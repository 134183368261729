import React from 'react'
import Accordion from 'react-bootstrap/Accordion';
import "./Policy.css"


export default function Policy() {



  return (
    <main>

      <section className='commonPadding'>
        <div className="container">
          <div className="commonHeading text-center">
            <div className="h1">Privacy Policy</div>
          </div>
          <div className='blogDetailContent termsPageContent'>

          <h5> Welcome to Shopyxa!</h5>
            <p>We have formed this privacy policy to demonstrate how we acquire, use, and protect your private data. Also, it elaborates on what choices you have regarding the use of the information  collected by us. By writing this policy, we aim to make our platform, Shopyxa, a safe and better place where merchants, users, and any other associated entity share their confidential data with us.</p>

            <h5>Data We Collect from You </h5>
            <h6>Personal Information </h6>
            <p><strong>Registration Details</strong></p>
            <p> We ask you to provide us with your personal information for registration. For example, email address, contact number, full name, and address to prove your identity.</p>
            <p><strong>Cookies information</strong></p>
            <p>Cookies information is meant to track the user behavior while browsing and the activities. These are small files containing a unique identifier and a small amount of data. You have the right to enable /disable the cookies.</p>
            <p><strong>Usage Data</strong></p>
            <p>Usage data includes browser address, type, version, etc; time/date of visit, and diagnostic data. Every time you visit our services, we may collect this information.</p>

            <p><strong>Third-Party Cookies</strong></p>
            <p>Third-party Cookies, stored on a different domain than you are currently browsing collect your data to access more information about you and your online behavior to display the relevant ads for you on that third domain. For instance, Facebook ads, Google ads, etc. To know in detail about the Google  ads cookies and Facebook ads cookies policy, you can click on the links below:</p>
            <p><a href='https://policies.google.com/technologies/ads?hl=en-US' target="_blank">https://policies.google.com/technologies/ads?hl=en-US</a></p>
            <p><a href='https://www.facebook.com/privacy/policies/cookies' target="_blank">https://www.facebook.com/privacy/policies/cookies</a></p>

            <h5>How  Do  We Use The Data Collected from You?</h5>
            <p> We may use the data provided to us for the following purposes:</p>
            <ul>
              <li>To offer and keep our service running.</li>
              <li>To track how our service is being used.</li>
              <li>To collect analysis to enhance our service.</li>
              <li>Detect, protect, and resolve technical issues.</li>
              <li>To keep you updated on changes to our Service.</li>
              <li>To enable you to participate in interactive features of our Service if you are interested.
                Unless you have opted not to receive such information, we will send you news, special offers, and general information about other products, services, and events that we provide that are equivalent to those you have already purchased or enquired about.</li>
              <li>To facilitate you with customer service.</li>
            </ul>
            <h5>Security of Your Data</h5>
            <ul>
              <li> We care about the security of your data, but you cannot deny that online/digital data is prone to risks. While we strive to protect your Personal Data using commercially acceptable means, we cannot ensure its complete protection due to the rising potential risks of cyber crimes. </li>

              <li> Shopyxa(Content Delivery Network) CDN is handled by Cloudflare, and it offers complete security, privacy, and integrity of all the data present on the network. To know more about Cloudflare, you can go through their privacy terms-
                <a href='https://www.cloudflare.com/privacypolicy/'> https://www.cloudflare.com/privacypolicy/ </a></li>

              <li> Our web hosting is managed by Amazon Web Services (AWS). To read more about their privacy terms  to get ensured about your privacy you can go through this page: <a href='https://aws.amazon.com/privacy/?nc1=f_pr'>https://aws.amazon.com/privacy/?nc1=f_pr</a> </li>

            </ul>

            <h5> Data Disclosure</h5>
            <p>We shall not share or sell your private data collected on our platform to any third party or person unless it is mandatory to do in response to the public authorities for any legal requirements.</p>
            <h5> Deletion of Data</h5>
            <p>You can contact us directly at <a href='mailto:support@shopyxa.com'>support@shopyxa.com</a> to request the deletion of your Personal Data.</p>
            
            <h5>Google Analytics</h5>
            <p>Google Analytics is a Google web analytics service that monitors and reports web traffic. Google employs the data gathered to track and monitor how our Services are used. Google may use the information gathered to reframe and customize ads on its own ad network.</p>
            <p>Please view the Google Privacy & Terms web page for more details on Google's privacy practices: <a href='https://policies.google.com/privacy?hl=en'>https://policies.google.com/privacy?hl=en</a> </p>

            <h5>Privacy Policy Updation</h5>
            <p>Before the changes become effective, we will inform you via email and update the "effective date" at the top of this Privacy Policy. Please take a look at this Privacy Policy regularly for any changes. When revisions to this Privacy Policy are updated on this page, they become effective.</p>

            <h5>Contact Us</h5>
            <p>If you have any questions about this Privacy Statement, please contact us at <a href='mailto:support@shopyxa.com'>support@shopyxa.com</a></p>

          </div>
        </div>
      </section>

    </main>
  )
}
