import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from '../Components/Header/Header';
import Footer from '../Components/Footer/Footer';
import Home from '../Components/Pages/Home/Home';
import Manage from '../Components/Pages/Manage/Manage';
import Sell from '../Components/Pages/Sell/Sell';
import Facebook from '../Components/Pages/Sell/Facebook/Facebook';
import Instagram from '../Components/Pages/Sell/Instagram/Instagram';
import Pricing from '../Components/Pages/Pricing/Pricing';
import Learn from '../Components/Pages/Learn/Learn';
import LearnDetail from '../Components/Pages/Learn/LearnDetail/LearnDetail';
import PageNotFound from '../PageNotFound';
import Faq from '../Components/Pages/Faq/Faq';
import 'bootstrap/dist/css/bootstrap.min.css';
import "../Assets/css/slick.min.css";
import ScrollToTop from '../Components/Common/ScrollToTop/ScrollToTop';
import Terms from '../Components/Pages/Terms/Terms';
import Policy from '../Components/Pages/Policy/Policy';
import './App.css';
import Contact from '../Components/Pages/Contact/Contact';
import RefundPolicy from '../Components/Pages/RefundPolicy/RefundPolicy';

export let appURL = ""
if (process.env.REACT_APP_MODE === 'prod') {
  console.log = function () { };
  appURL = "shopyxa.com"
} else if (process.env.REACT_APP_MODE === 'dev') {
  appURL = "forgetaday.com"
} else {
  appURL = "shopyxo.com"
}


export const loginUrl = `https://accounts.${appURL}/login`;
export const signUpUrl = `https://accounts.${appURL}/signup`;

function App() {
  return (
    <div className="App">
      <Router>
        <Header />
        <ScrollToTop />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="*" element={<PageNotFound />} />
          <Route exact path="/manage" element={<Manage />} />
          <Route exact path="/sell" element={<Sell />} />
          <Route exact path="/sell/facebook" element={<Facebook />} />
          <Route exact path="/sell/instagram" element={<Instagram />} />
          <Route exact path="/pricing" element={<Pricing />} />
          <Route exact path="/learn" element={<Learn />} />
          <Route exact path="/learn/detail" element={<LearnDetail />} />
          <Route exact path="/faq" element={<Faq />} />
          <Route exact path="/terms-of-use" element={<Terms />} />
          <Route exact path="/privacy-policy" element={<Policy />} />
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="/refund-policy" element={<RefundPolicy />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
