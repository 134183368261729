import React from 'react'
import "./TwoCol.css"
import { signUpUrl } from '../../../Container/App'
import Fade from 'react-reveal/Fade';
import Flip from 'react-reveal/Flip';
export default function TwoCol({ imgRight, img, title, content, btnText, alt }) {
    return (
        <div className={`${imgRight ? "imgRight flex-md-row-reverse" : " justify-content-end"} twoCol row align-items-center`}>
            <div className={`col-md-${imgRight ? "6" : "5"} `}>
                <div className="twoCol__img">
                    <Flip top delay={100}>
                        <img src={img} className="img-fluid w-100 d-block" alt={alt} />
                    </Flip>
                </div>
            </div>
            <div className={`col-md-${imgRight ? "5" : "6"} `}>
                <div className="twoCol__cntnt">
                    <Fade up> <div className="h1" dangerouslySetInnerHTML={{ __html: title }}></div></Fade>
                    <Fade up> <p>{content}</p></Fade>
                    {/* <button className='btn btn-primary'>{btnText}</button> */}
                    <Fade up> <a href={signUpUrl} className="btn btn-primary">{btnText} </a></Fade>
                </div>
            </div>
        </div>
    )
}
