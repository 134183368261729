import React from 'react'
import { Link } from 'react-router-dom';
import Index from '../../../../Assets/Images/Index'
import { signUpUrl } from '../../../../Container/App';
import SellingBox from '../../../Common/SellingBox/SellingBox';
import "./LearnDetail.css"

export default function LearnDetail() {
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
     
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1, 
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const learnBlogList = [

    {
      img: Index.learnBlog1,
      title: "Get Ready for Black Friday and Cyber Monday",
      author: "James All",
      date: "Oct 28, 2022",
    },


  ]

  const recomendedList = [
    {
      title: "Save Time and Money with Lightspeed Payments",
      link: "/learn/detail",
    },
    {
      title: "Best Practices For Ecommerce Shipping During The Holiday",
      link: "/learn/detail",
    },
    {
      title: "A Foolproof Advertising Strategy for the Holiday Season",
      link: "/learn/detail",
    },
    {
      title: "Best Practices For Ecommerce Shipping During ",
      link: "/learn/detail",
    },
    {
      title: "Advertising Smart on Facebook and Google with Kliken",
      link: "/learn/detail",
    },

  ]

  return (
    <main>
      <section className='greyBg commonPadding'>
        <div className="container">
          <div className="commonHeading text-center mb-0">
            <div className="h1 mb-0">Shopyxa Blog</div>
          </div>
        </div>
      </section>
      <section className='commonPadding'>
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-md-8 col-xl-8">
              <div className="h1">Get Ready for Black Friday and Cyber Monday with Shopyxa</div>
              <div className="shareDetailbox d-flex justify-content-between">
                <div className="LearnBox__detail">
                  <span className="authorby textBlack me-1">By</span>
                  <span className="author primaryClr me-1">James All</span>
                  <span className="date">Oct 28, 2022</span>
                </div>
                {/* <div className="LearnBox__detail">
                  <ul className="d-flex p-0 socialHandle">
                    <li><a href='#' target="_blank"><img src={Index.sqrfacebook} alt="" /></a></li>
                    <li><a href='#' target="_blank"><img src={Index.sqrlinkedin} alt="" /></a></li>
                    <li><a href='#' target="_blank"><img src={Index.sqrtwitter} alt="" /></a></li>
                    <li><a href='#' target="_blank"><img src={Index.sqrenvelope} alt="" /></a></li>
                  </ul>
                </div> */}
              </div>
              <div className="blogDetailContent">
                <p>Black Friday and Cyber Monday are two of the biggest shopping days of the year for both online and brick-and-mortar stores. In 2021, consumers spent more than $204 billion during the holiday season.</p>
                <p>You can take advantage of this spending peak too.</p>
                <ul>
                  <li>an take advantage of this spending peak too</li>
                  <li>an take advantage of this spending peak too</li>
                  <li>an take advantage of this spending peak too</li>
                  <li>an take advantage of this spending peak too</li>
                </ul>
                <p>By planning and advertising meticulously, businesses can make the most profits during what is known as the Golden Quarter. This time period allows sellers in all markets to experience big boosts in their business.</p>
                <img src={Index.learnBlogInr} alt="" />
                <p>If you want to rapidly increase your sales, make sure you’re ready for the Holiday Season.</p>
                <p>But how can your ecommerce store take advantage of this holiday’s spending boom? How should you plan promotions, email campaigns, and discounts to maximize sales? What are the best promotional channels for your store?</p>
                <p>If you’re selling with Ecwid, you can feel confident that you’re in good hands! Your online store is packed with tools to help you succeed in this holiday rush.</p>
                <p>You can discover new features through the Ecwid Igniter: a series full of tips and tricks for optimizing your online store. Check it out and kickstart your earning potential for Black Friday, Cyber Monday, and peak shopping seasons.</p>
                <p>To give you even more of an advantage, we hosted a webinar specifically about how to rise above your competition during the holidays. If you’ve missed it, don’t worry—we recorded it so you can watch the webinar recording whenever you want.</p>
              </div>

              <div className="authorDetailBox greyBg">
                <div className="authorDetailBox__row d-flex align-items-center">
                  <div className="authorDetailBox__img">
                    <img src={Index.authorImg} alt="" />
                  </div>
                  <div className="authorDetailBox__detail">
                    <h4> About Author</h4>
                    <p>James has been working in the ecommerce industry for the last six years helping brands to establish and level-up content marketing and SEO. </p>
                  </div>
                </div>
              </div>

            </div>
            <div className="col-md-4 col-xl-3 hideonMobile">
              <div className="subscribeBox__grp mb-3">
                <input type="search" className="form-control" placeholder="Search" />
                <button className="subscribeBox__button" type="button" id="button-addon2">
                  <img src={Index.search} alt="" />
                </button>
              </div>
              <div className="learnRecomendBox">
                <div className="learnRecomendBox fontBold h6">Recommended </div>
                <ul>
                  {recomendedList.map((data, indx) => {
                    return (
                      <li key={indx}>
                        <Link to={data.link}>{data.title}</Link>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <SellingBox
        title="Start Selling Online for Free"
        btnText="Start Free Trial"
        cntntPos="center" />
    </main>
  )
}
