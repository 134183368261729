import React from 'react'
import { Link } from 'react-router-dom' 
import Img404 from './Assets/Images/404.svg'

export default function PageNotFound() {
  return (
    <div className="verifyPage d-flex align-items-center justify-content-center">
            <div className="verifyPage__container">
                <img src={Img404} className="img-fluid d-block" alt="" />
                <div className="verifyPage__content text-center">
                    <h4  className="mb-0">404 Page not found</h4>
                    <p className='text-greytint2'>Sorry but the page you are looking for does not exist, have been removed. name changed or is temporarily unavailable</p>
                    <Link to="/" className='btn btn-primary'>Go To Homepage</Link>
                </div>
            </div>
        </div>
  )
}
