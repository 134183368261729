import React, { useState, useEffect } from 'react'
import { Link, NavLink } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Index from '../../Assets/Images/Index';
import { loginUrl } from '../../Container/App';
import { signUpUrl } from '../../Container/App';
import { useMediaQuery } from "@uidotdev/usehooks";

import './Header.css'
export default function Header() {
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const [width, setWidth] = useState(window.innerWidth);
    const [nav, setNav] = useState([
        {
            type: "nav", name: "Sell", link: "/sell",
            // submenu: [{ name: "Sell on Facebook", link: "/sell/facebook", }, { name: "Sell on Instagram", link: "/sell/instagram", }], 
            pos: "left", dropdown: false,
        },
        // { type: "nav", name: "Market", link: "/market", pos: "left", dropdown: false, },
        { type: "nav", name: "Manage", link: "/manage", pos: "left", dropdown: false, },
        // { type: "nav", name: "Learn", link: "/learn", pos: "right", dropdown: false, },
        { type: "anchor", name: "Support", link: "https://help.shopyxa.com/support/home", pos: "right", dropdown: false, },
        { type: "nav", name: "Pricing", link: "/pricing", pos: "right", dropdown: false, },
        { type: "anchor", name: "Login", link: loginUrl, pos: "right", dropdown: false, },
    ])
    const toggleDropdorn = (i) => {
        let updatedMenu = []
        nav.map((data, index) => {
            if (i == index) {
                updatedMenu.push({ ...data, 'dropdown': !data.dropdown })
            } else {
                updatedMenu.push({ ...data, 'dropdown': false })
            }
        })
        setNav(updatedMenu)
    }
    useEffect(() => {
        function handleResize() {
            setWidth(window.innerWidth);
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [width]);


    const togglerClick = () => {
        if (width < 992) {
            document.getElementById("navbarToggle").click();
        }
    }

    return (
        <div>
            <div className="headerHeight"></div>
            <div className='navbar_outer'>
                <div className='announcement_bar'>
                    <NavLink to="/pricing"><strong>Festival offer:</strong> Get 50% off on all plans.</NavLink>
                </div>
                <Navbar expand="lg">
                    <Container>
                        <Link to="/" className="navbar-brand"><img src={prefersDarkMode ? Index.logoWhite : Index.logo} alt="" width="173" /></Link>
                        <Navbar.Toggle id="navbarToggle" aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <div className='navbarMainRow d-flex justify-content-between w-100'>
                                <div className="navbarMain__left">
                                    <ul className="navbarMain__ul">
                                        {nav.filter(cntnt => cntnt.pos === "left").map((item, i) => {
                                            return (

                                                <li key={i.toString()} className={`${item.submenu ? "dropdown" : ""} ${item.dropdown ? "openmenu" : ""}`}>
                                                    {item.type === "nav" ?
                                                        <NavLink onClick={() => togglerClick()} to={item.link} className="headerMain__listBtn">{item.name} </NavLink> :
                                                        <a onClick={() => togglerClick()} href={item.link} className="headerMain__listBtn 12122">{item.name} </a>
                                                    }
                                                    {item.submenu ?
                                                        <>
                                                            <button className='caret' onClick={() => toggleDropdorn(i)}></button>
                                                            <ul className="submenu ">
                                                                {item.submenu.map((data, ind) => {
                                                                    return (
                                                                        <li key={ind.toString()}>

                                                                            <NavLink onClick={() => togglerClick()} to={data.link} className="headerMain__listBtn">{data.name} </NavLink>

                                                                        </li>
                                                                    )
                                                                })}
                                                            </ul>
                                                        </>
                                                        : null}
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </div>
                                <div className="navbarMain__right">
                                    <ul className="navbarMain__ul">
                                        {nav.filter(cntnt => cntnt.pos === "right").map((item, i) => {
                                            return (
                                                <li key={i.toString()} className={`${item.submenu ? "dropdown" : null}`}>
                                                    {item.type === "nav" ?
                                                        <NavLink onClick={() => togglerClick()} to={item.link} className="headerMain__listBtn">{item.name} </NavLink> :
                                                        <a onClick={() => togglerClick()} href={item.link} className="headerMain__listBtn 12122">{item.name} </a>
                                                    }
                                                    {item.submenu ?
                                                        <>
                                                            <span className='caret'></span>
                                                            <ul className="submenu ">
                                                                {item.submenu.map((data, ind) => {
                                                                    return (
                                                                        <li key={ind.toString()}>
                                                                            <NavLink to={data.link} className="headerMain__listBtn">{data.name} </NavLink>
                                                                        </li>
                                                                    )
                                                                })}
                                                            </ul>
                                                        </>
                                                        : null}
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </div>
                            </div>
                        </Navbar.Collapse>
                        <a href={signUpUrl} className="navbarBtn btn btn-primary">Start Free Trial </a>
                    </Container>
                </Navbar>
            </div>
        </div>
    )
}
