import React, { useEffect, useState } from 'react'
import Index from '../../../Assets/Images/Index'
import BannerContent from '../../Common/BannerContent/BannerContent'
import IconBox from '../../Common/IconBox/IconBox'
import TwoCol from '../../Common/TwoCol/TwoCol'
import TestimonialCard from '../../Common/TestimonialCard/TestimonialCard'
import Slider from "react-slick";
import { signUpUrl } from '../../../Container/App'
import SellingBox from '../../Common/SellingBox/SellingBox'
import Fade from 'react-reveal/Fade';


import "./Home.css"


export default function Home() {
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
    responsive: [

      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };


  const onePlatform = [
    {
      img: Index.mobilecart,
      title: "Fully Customized Stores",
      content: "We make it possible for you to get industry-specific themes to create a digital store. You can customize your digital store whenever you need as the market trend changes."
    },
    {
      img: Index.card,
      title: "Multi Gateway Payment",
      content: "Flexible payment modes enable you to engage more customers with your brand without any barriers. Integrate the best possible payment modes to meet your needs."
    },
    {
      img: Index.mail,
      title: "24*5 Tech Support",
      content: "We don't make you wait to get your queries resolved. We work around the clock to fulfill your ecommerce service requirements. You can reach us anytime for technical assistance."
    },
    {
      img: Index.chart,
      title: "Sales Escalation",
      content: "An aesthetically designed and perfectly optimized store helps you to be easily visible on the web and attract heavy traffic which leads to high conversion rates."
    },
    {
      img: Index.laptop,
      title: "Full Maintenance ",
      content: "Our job is not completed just after launching your digital store. We can offer you complete maintenance assistance for the flawless online working of your business store."
    },
    {
      img: Index.bulb,
      title: "Excellent Teams",
      content: "We have top-seeded industry experts with us who are committed to delivering on-time services to our clients without fail. Our experts will help you grow in the digital landscape."
    },
  ]
  const sellBoxData = [
    {
      title: "Sell on Facebook",
      content: "Set up your Shopyxa store once to easily sync and  sell across a website",
      mainImg: Index.facebookBox,
      icon: Index.bxl_facebook_circle,
    },
    {
      title: "Sell on Instagram",
      content: "Start Selling on Instagram with your Instagram Store",
      mainImg: Index.instagramBox,
      icon: Index.bxl_instagram,
    },
    {
      title: "Sell on Google Shoping",
      content: "Amplify your business with Google Shopping",
      mainImg: Index.googleBox,
      icon: Index.google_shopping,
    },

  ]
  const testimonialContent = [
    {
      personImg: Index.kavitaKausal,
      name: "Kavita Kausal",
      position: "CEO",
      review: "I've found Shopyxa to be the best alternative for an e-commerce store-building platform. My brand is recognized nationwide now! All thanks to Shopyxa, which has the best potential for displaying my products online at a low cost."
    },
    {
      personImg: Index.pawasMahajan,
      name: "Pawas Mahajan",
      position: "CEO",
      review: "I started with their 15 days free trial, was impressed with their Shopyxa platform, and decided to maintain a long-term relationship with them to enjoy their services and improve my store."
    },
    {
      personImg: Index.judieLastina,
      name: "Judie Lastina ",
      position: "CEO",
      review: "With my Shopyxa store, I am able to reach my target customers across all of India. I can get deep insights into the data and observe my audience’s behavior, which helps me grow further. You should definitely try this platform if you are looking for high-class services on a low budget."
    },


  ]
  const twoColSell = [
    {
      img: Index.sellanywhere,
      title: "A Successful Digital Store",
      content: "Take your brand to the next level with extraordinary inventory management, order tracking, and real-time data insights. Our hands-on experience with the latest tools gives you unique recognition among your rivals.",
      btnText: "Start Free Trial",
    }
  ]
  const growFaster = [
    {
      img: Index.growfaster,
      title: "Easy to <br/> Manage & Sell",
      content: "Manage everything from inventory to pricing and other features from a single dashboard. Your products are ready to be sold  handily in less time",
      btnText: "Start Free Trial",
    }
  ]
  const [error, seterror] = useState({
    show: false,
    msg: ""
  })
  const [email, setEmail] = useState("")
  const handleEmail = (e) => {
    setEmail(e.target.value)
  }
  const submitEmail = (e) => {
    e.preventDefault();
    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

    if (email == "") {
      seterror({
        show: true,
        msg: "Please enter your email"
      })
    } else if (email !== "" && !pattern.test(email)) {
      seterror({
        show: true,
        msg: "Please enter valid email"
      })
    } else {
      seterror({
        show: false,
        msg: ""
      })
      window.open(`${signUpUrl}?email=${email}`, "_self")
    }
  }

  return (
    <main>
      <BannerContent
        bannerClass="homeBanner"
        form
        title="India's Top Ecommerce Solution Provider"
        cntnt="We offer you high-quality and low-cost Ecommerce services without any hassle. Create digital store with no code."
        img1={Index.headerbanner1}
        absImg
        alt="ecommerce services"
        img2={Index.headerbanner2}
        btnText="Start Your Free Trial"
        handleEmail={handleEmail}
        submitEmail={submitEmail}
        error={error}
      />
      <section className='fewClickSctn commonPadding'>
        <div className="container">
          <div className="commonHeading text-center">
            <Fade up><div className="h1">  Make an Impressive Digital Identity<br /> with Fewer Efforts </div></Fade>
            <Fade up> <a href={signUpUrl} className="btn btn-primary">Start Free Trial</a></Fade>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-10">
              <Fade up> <img src={Index.freeonlinestore} className="img-fluid w-100 d-block" alt="online store" /></Fade>
            </div>
          </div>
        </div>
      </section>
      <section className='commonPadding'>
        <div className="container">
          <div className="commonHeading centerMobile">
            <Fade up delay={300}> <div className="h6 primaryClr">Why Shopyxa</div></Fade>
            <Fade up delay={400}> <div className="h1">Build Your Customer Base<br /> Nationwide Quickly </div></Fade>
          </div>
          <div className="IconBoxRow">
            {/* {onePlatform.map((data, ind) => {
              return ( */}

            <IconBox data={onePlatform}
            // key={ind}
            // icon={data.img}
            // title={data.title}
            // content={data.content}
            />

            {/* )
             })} */}
          </div>
        </div>
      </section>
      <section className='twocolSect commonPadding'>
        <div className="container">
          {twoColSell.map((data, indx) => {
            return (
              <TwoCol
                key={indx}
                img={data.img}
                alt="digital store"
                title={data.title}
                content={data.content}
                btnText={data.btnText}
              />
            )
          })}

        </div>
      </section>
      <section className='twocolSect commonPadding'>
        <div className="container">
          {growFaster.map((data, indx) => {
            return (
              <TwoCol
                key={indx}
                imgRight
                img={data.img}
                alt="ecommerce platform"
                title={data.title}
                content={data.content}
                btnText={data.btnText}
              />
            )
          })}

        </div>
      </section>
      {/* <section className='commonPadding'>
        <div className="container">
          <div className="commonHeading text-center">
            <Fade up>     <div className="h1">On the Horizon……..Social Media Selling </div></Fade>
          </div>
          <div className="row">
            {sellBoxData.map((data, indx) => {
              return (
                <Fade up delay={indx * 400} key={indx}>
                  <div className="col-md-4">
                    <div className="homeSellBox">
                      <div className="homeSellBox__img">
                        <img src={data.mainImg} alt="" />
                        <div className="homeSellBox__absImg">
                          <img src={data.icon} alt="" />
                        </div>
                      </div>
                      <div className="homeSellBox__cntnt text-center">
                        <h5>{data.title}</h5>
                        <p>{data.content}</p>
                      </div>
                    </div>
                  </div>
                </Fade>
              )
            })}

          </div>
        </div>
      </section> */}
      <section className='testimonialSection commonPadding'>
        <div className="container">
          <div className="commonHeading">
            <Fade up> <div className="h1">Meet the merchants who love Shopyxa </div> </Fade>
          </div>
          <div className='testimonialSlider'>
            <Fade up>
              <Slider {...settings}>
                {testimonialContent.map((data, indx) => {
                  return (
                    <TestimonialCard
                      key={indx}
                      personImg={data.personImg}
                      name={data.name}
                      position={data.position}
                      review={data.review}
                    />
                  )
                })}
              </Slider>
            </Fade>
          </div>
        </div>
      </section>
      <SellingBox
        title="Supporting Tomorrow's Entrepreneurs Today"
        btnText="15 Days Free Trial"
        cntntPos="center" />

    </main>
  )
}
