import React from 'react'
import CurrencyFormat from 'react-currency-format';
import "./Contact.css"
import { useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Index from '../../../Assets/Images/Index';


export default function Contact() {
  const [value, setValue] = useState({
    name: "",
    email: "",
    phone: "",
    type: "",
    message: "",
  })
  const [alerts, setAlerts] = useState({
    show: false,
    type: "success",
    msg: ""
  })

  const [errors, setErrors] = useState(false)
  const [btnLoader, setbtnLoader] = useState(false)

  const handleChange = (e) => {
    setValue({ ...value, [e.target.name]: e.target.value })
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    setbtnLoader(true)
    if (value.name == "" || value.email == "") {
      setbtnLoader(false)
      setErrors(true)
    } else {
      window.Email.send({
        Host: "smtp.elasticemail.com",
        Port: "2525",
        Username: "bsboby@gmail.com",
        // Password: "ACA8EB2268A95BE8CEB79EF24E39EBBD9D1F",
        Password: "83F09F4F13F7950BEE8BD39BCC4A18BDEFB8",
        From: "bsboby@gmail.com",
        To: "support@psd2html5.co",
        Subject: "Shopyxa | Consultation Request Received",
        Body: `<p>Name: ${value?.name}</p>
            <p>Email: ${value?.email}</p>
            <p>Phone number: ${value?.phone || "NA"}</p>
            <p>Query type: ${value?.type || "NA"}</p>
            <p>Message: ${value?.message || "NA"}</p>
            `,
      }).then((res) => {
        if (res == "OK") {
          setAlerts({ show: true, type: "success", msg: "Thanks for getting in touch with us! We have received your message request. Our team will be responding shortly." })
          setValue({
            name: "",
            email: "",
            phone: "",
            type: "",
            message: "",
          })
          setErrors(false)
          setbtnLoader(false)
          window.Email.send({
            Host: "smtp.elasticemail.com",
            Port: "2525",
            Username: "bsboby@gmail.com",
            Password: "83F09F4F13F7950BEE8BD39BCC4A18BDEFB8",
            From: "bsboby@gmail.com",
            To: value?.email,
            Subject: "Thanks for reaching us!",
            Body: `<p>Thanks for getting in touch with us! We have received your message request. Our team will be responding shortly.</p>`,
          }).then();
          setTimeout(() => {
            setAlerts({ show: false, type: "success", msg: "" })
          }, 3000);
        } else {
          setAlerts({ show: true, type: "error", msg: "Email not sent." })
          setTimeout(() => {
            setAlerts({ show: false, type: "success", msg: "" })
          }, 3000);
        }
      });
    }

  }
  console.log("value: ", value)
  return (
    <main className='verifyPage'>

      <section className=''>
        <div className="container">
          <div className="commonHeading text-center">
            <div className="h1">Get in touch</div>
          </div>
          <div className='blogDetailContent'>
            <div className='row justify-content-between'>
              <div className='col-md-5'>
                <div className='contact_address_list'>
                  <div className='contact_address_icon'>
                    <img src={Index.map_icon} alt="" width={30} />
                  </div>
                  <div className='contact_address_text'>
                    <h5 className='mb-1'>Address</h5>
                    <p>Shopyxa <br /> Office: 918, 9th Floor, Tower - B, <br />Bestech Business Towers, <br /> Sector - 66, Mohali - 160066</p>
                  </div>
                </div>
                <div className='contact_address_list'>
                  <div className='contact_address_icon'>
                    <img src={Index.email_icon} alt="" width={30} />
                  </div>
                  <div className='contact_address_text'>
                    <h5 className='mb-1'>Email</h5>
                    <p><a href='mailto:support@shopyxa.com'>support@shopyxa.com</a></p>
                  </div>
                </div>
                <div className='contact_address_list'>
                  <div className='contact_address_icon'>
                    <img src={Index.phone_icon} alt="" width={30} />
                  </div>
                  <div className='contact_address_text'>
                    <h5 className='mb-1'>Phone</h5>
                    <p className='mb-2'><a href='tel:1724005441'>+91 172 4005441</a>, <a href='tel:1724025441'> 4025441</a></p>
                  </div>
                </div>
                {alerts.show ? <Alert variant="success">
                  {alerts.msg}
                </Alert> : null}

              </div>
              <div className='col-md-5'>
                <form onSubmit={handleSubmit}>
                  <input type="text" className={`form-control w-100 mb-3 ${errors && value.name == "" ? "error" : ""}`} name="name" value={value.name} placeholder="Your name*" aria-label="Your name" onChange={handleChange} />
                  <input type="email" className={`form-control w-100 mb-3 ${errors && value.email == "" ? "error" : ""}`} name="email" value={value.email} placeholder="Email Address*" aria-label="Email Address" onChange={handleChange} />
                  <CurrencyFormat format="+91 ##########" mask="" name="phone" value={value.phone} className="form-control w-100 mb-3" placeholder="Phone number" onChange={handleChange} />
                  <div className='form-select-outer' onChange={handleChange}>
                    <select className='form-control w-100 mb-3' name="type" value={value.type}>
                      <option value="" disabled selected>Inquiry Type</option>
                      <option value={"General inquiries"}>General inquiries</option>
                      <option value={"Product or service questions"}>Product or service questions</option>
                      <option value={"Technical support inquiries"}>Technical support inquiries</option>
                      <option value={"Sales inquiries"}>Sales inquiries</option>
                      <option value={"Feedback inquiries"}>Feedback inquiries</option>
                    </select>
                  </div>
                  <textarea className="form-control w-100 mb-3" style={{ height: "auto", maxHeight: "300px" }} value={value.message} placeholder="Message" aria-label="Message" rows={5} name="message" onChange={handleChange} />
                  {btnLoader ?
                    <div className='btn btn-primary w-100'>Sending...</div>
                    :
                    // <button className='btn btn-primary w-100'>{alerts.show ? alerts.msg : "Submit"}</button>
                    <button className='btn btn-primary w-100'>Submit</button>
                  }


                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

    </main>
  )
}
