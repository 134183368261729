import React from 'react';
import { Link, NavLink, useNavigate } from "react-router-dom";
import './Footer.css';
import Index from '../../Assets/Images/Index';


export default function Footer() {
  const footerLinks = [
    {
      name: "About", submenu: [
        { name: "Sell", link: "/sell", },
        // { name: "Market", link: "/market", },
        { name: "Manage", link: "/manage", },
        { name: "Learn", link: "/learn", },
        { name: "Pricing", link: "/pricing", },
        { name: "Contact", link: "/contact", },
        { name: "FAQ", link: "/faq", },
      ]
    },
    // {
    //   name: "Products", submenu: [
    //     { name: "Features", link: "/", }, 
    //     { name: "Watch Demo", link: "/", },
    //     { name: "Buy Now Button", link: "/", },
    //     { name: "Ecommerce Hosting", link: "/", },
    //     { name: "Point-of-Sale", link: "/", },
    //   ]
    // },
    // {
    //   name: "Resources", submenu: [
    //     { name: "Products to sell", link: "/", },
    //     { name: "How to Sell Online", link: "/", },
    //     { name: "Create an Online Store", link: "/", },
    //     { name: "Start a Business", link: "/", },
    //     { name: "Ecom Glossary", link: "/", },
    //     { name: "Ecommerce Insights", link: "/", },
    //   ]
    // },


  ]
  return (
    <footer className='footermain'>
      <div className="footermain__Top">
        <div className="container">
          <div className="row justify-content-between">
            {/* <div className="col-md-3 col-lg-4">
              <div className='mb-3 footer__logo'>
              <Link to="/">  <img  src={Index.logoWhite} width="200"/> </Link>
              </div> */}
            {/* <div className='subscribeBox'>
                <div className="h5 footerMenuBox__title">Subscribe email</div>
                <div className="subscribeBox__grp mb-3">
                  <input type="email" className="form-control" placeholder="Email Address" aria-label="Email Address" aria-describedby="button-addon2" />
                  <button className="subscribeBox__button" type="button" id="button-addon2"><img src={Index.envelope} /></button>
                </div>
              </div> */}

            {/* </div> */}
            {/* <div className="col-md-9 col-lg-8"> */}
            <div className="col-12">
              <div className="text-center">
                {footerLinks.map((data, ind) => {
                  return (
                    <div key={ind} className="">
                      {/* <div key={ind} className="col-6 col-sm-4 col-md-4"> */}
                      <div className="footerMenuBox">
                        {/* <h5 className="footerMenuBox__title">{data.name}</h5> */}

                        <ul className="">
                          {data.submenu.map((cntnt, indx) => {
                            return (
                              <li key={indx}>  <NavLink to={cntnt.link} >{cntnt.name} </NavLink> </li>
                            )
                          })}
                        </ul>
                      </div>
                    </div>
                  )
                })}
              </div>
              {/* <div className="text-center">
                <ul className="footer__social">
                  <li><a href='#' target="_blanck"><img src={Index.facebook} alt="" /></a> </li>
                  <li><a href='#' target="_blanck"><img src={Index.twitter} alt="" /></a> </li>
                  <li><a href='#' target="_blanck"><img src={Index.instagram} alt="" /></a> </li>
                  <li><a href='#' target="_blanck"><img src={Index.youtube} alt="" /></a> </li>
                </ul>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="footermain__bottom">
        <div className="container">
          <div className="footermain__bottomCntnt d-flex">
            {/* <div className="footermain__bottomLogo">
              <img src={Index.logoWhite} className="img-fluid" alt="" width="158" />
            </div> */}
            <div className="footermain__bottomLinks d-flex flex-md-row-reverse w-100 justify-content-between align-items-center">
              <ul className='m-0 p-0 d-flex '>
                <li><Link to="terms-of-use">Terms of use</Link> </li>
                <li><Link to="privacy-policy">Privacy Policy </Link> </li>
                <li><Link to="refund-policy">Refund Policy </Link> </li>
              </ul>
              <div className="copyright">Shopyxa is a product from <a href='https://www.webexpertsonline.net/' target='_blank'>Web Experts Online</a></div>
              <div className="copyright">© Copyrights {new Date().getFullYear()}. All Rights Reserved</div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}
