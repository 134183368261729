import React from 'react'
import Index from '../../../Assets/Images/Index'
import BannerContent from '../../Common/BannerContent/BannerContent'
import TwoCol from '../../Common/TwoCol/TwoCol'
import { signUpUrl } from '../../../Container/App'
import Fade from 'react-reveal/Fade';
import Flip from 'react-reveal/Flip';
import "./Sell.css"
import { useMediaQuery } from "@uidotdev/usehooks";
import { Helmet } from 'react-helmet'

export default function Sell() {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const sellonwebsite = [
    {
      img: Index.sellonwebsite,
      title: "Cutting-edge eCommerce Platform",
      content: "Experience all e-commerce solutions under one roof with Shopyxa. Sell your products and services on customized Shopyxa stores. Grow your revenue with fast-loading and optimized online stores.",
      btnText: "Start Free Trial",
    }
  ]
  const sellOnSocial = [
    {
      img: Index.sellonSocial,
      title: "Sell With No Worries",
      content: "Are you selling products online from home? Want your products in the spotlight? The answer is the Shopyxa store, where you can leverage the advantages of extended features and functionalities to reach a wider audience.",
      btnText: "Start Free Trial",
    }
  ]
  const sellonMarket = [
    {
      img: Index.sellonMarket,
      title: "Launch Optimized Store",
      content: "Theme layouts follow effective ideas to catch visitors' eyes. You can spice up your store with optimized titles, descriptions, and high-quality pictures. Hundreds of entrepreneurs trusted Shopyxa, an all-in-one e-commerce platform, to build a strong digital presence.",
      btnText: "Start Free Trial",
    }
  ]
  return (
    <main>
      
      <Helmet>
        <title>Start Selling Products online from home-No Hassle with Shopyxa.</title>
        <meta name="description" content="All-in-one Ecommerce Platform to Start Your Business from Home- Shopyxa.Feature-rich ecommerce website builder at reasonable prices." />
        <meta name="apple-mobile-web-app-title" content="Start Selling Products online from home-No Hassle with Shopyxa." />
        <meta name="twitter:title" content="Start Selling Products online from home-No Hassle with Shopyxa." />
        <meta name="twitter:description" content="All-in-one Ecommerce Platform to Start Your Business from Home- Shopyxa.Feature-rich ecommerce website builder at reasonable prices." />
      </Helmet>
      <BannerContent
        bannerClass="sellBanner"
        title="All-in-one ecommerce Platform"
        cntnt="We aim to offer you the best-fit services tailored to your needs. Hurry Up!"
        img1={Index.sellBanner}
        absImg
        alt="best ecommerce platform"
        img2={Index.sellBanner2}
        btnText="Get Free Trial"
      />
      <section className='commonPadding sellAroundWorld'>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-5">
              <div className="sellAroundWorld__Img">
              <Flip right><img src={prefersDarkMode ? Index.globeWhite : Index.globePng} alt="" /></Flip>
              </div>
            </div>
            <div className="col-md-7">
              <div className="sellAroundWorld__right">
                <div className="sellAroundWorld__rightTitle">
                  <Fade up>  <div className="h1 mb-3">Sell Across the Nation with Ease </div></Fade>
                  <Fade up delay={200}><div className="p">Best Online Store Platform for Small Business</div></Fade>
                </div>
                <div className='sellAroundWorld__grid'>
                  <div className='sellAroundWorld__box'>
                    <Fade up> <div className='title'>10</div></Fade>
                    <Fade up><div className='h6'>Payment Gateway</div></Fade>
                  </div>
                  <div className='sellAroundWorld__box'>
                    <Fade up><div className='title'>25321</div></Fade>
                    <Fade up><div className='h6'>Merchant on Shopyxa </div></Fade>
                  </div>
                  <div className='sellAroundWorld__box'>
                    <Fade up> <div className='title'>20+</div></Fade>
                    <Fade up> <div className='h6'>Cities Covered </div></Fade>
                  </div>
                  <div className='sellAroundWorld__box'>
                    <Fade up> <div className='title'>₹2.3M</div></Fade>
                    <Fade up><div className='h6'>Economic Activity</div></Fade>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </section>

      <section className='twocolSect commonPadding'>
        <div className="container">
          {sellonwebsite.map((data, indx) => {
            return (
              <TwoCol
                key={indx}
                alt="ecommerce solutions"
                img={data.img}
                title={data.title}
                content={data.content}
                btnText={data.btnText}
              />
            )
          })}
        </div>
      </section>
      <section className='twocolSect commonPadding'>
        <div className="container">
          {sellOnSocial.map((data, indx) => {
            return (
              <TwoCol
                imgRight
                key={indx}
                alt={"make online store"}
                img={data.img}
                title={data.title}
                content={data.content}
                btnText={data.btnText}
              />
            )
          })}
        </div>
      </section>
      <section className='twocolSect commonPadding'>
        <div className="container">
          {sellonMarket.map((data, indx) => {
            return (
              <TwoCol
                key={indx}
                img={data.img}
                alt={"best digital store"}
                title={data.title}
                content={data.content}
                btnText={data.btnText}
              />
            )
          })}
        </div>
      </section>
      <section className='commonPadding'>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-10">
              <div className="startSellingBox">
                <div className="row ">
                  <div className="col-md-5">
                  <Flip left> <img src={Index.startSelling} className="startSellingBox__img ms-auto d-block img-fluid" alt="get ecommerce platform" /></Flip>
                  </div>
                  <div className="col-md-7">
                    <div className="startSellingBox__cntnt">
                      <Fade up> <div className="h6"> Your online store is just a few clicks away!</div></Fade>
                      <Fade up> <div className="h1"> Start with 15 days Free Trial</div></Fade>
                      {/* <button className='btn btn-dark'>Start Free Trial </button> */}
                      <Fade up> <a href={signUpUrl} className="btn btn-dark">Get Started</a></Fade>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  )
}
