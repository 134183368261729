import React from 'react'
import Accordion from 'react-bootstrap/Accordion';
import "./Terms.css"


export default function Terms() {



  return (
    <main>

      <section className='commonPadding'>
        <div className="container">
          <div className="commonHeading text-center">
            <div className="h1">Terms of Use</div>
          </div>
          <div className='blogDetailContent termsPageContent'>

          <h5> Welcome to Shopyxa!</h5>
            <p>By signing up for a Shopyxa Account or by using any Shopyxa Services, you are agreeing to be bound by the following terms and conditions.</p>

            <p> “We”, “us”, “our” or “Company” shall mean Shopyxa or any of its licensees, and “you” address the Shopyxa user whether now or in the future.</p>
            <p> Shopyxa is an ecommerce platform that allows merchants to perform commerce operations as a whole. The platform offers a variety of features to craft a customized ecommerce store. </p>
            <p>With coming future may allow merchants to sell their products on various social media channels. The merchants can manage the products, customers, inventory, payments, marketing, and other business operations related to selling business.</p>
            <p>Any other features or tools added to Shopyxa in the coming time will be subject to the Terms of the services mentioned here. You need to agree with the mentioned terms of services before signing up for your Shopyxa account. Also, make sure to regularly check for updates in our Terms of Services.</p>

            <h5>General Conditions</h5>
            <ol>
              <li> The Terms of Service, along with the documents incorporated by reference by Shopyxa represent the complete agreement between you and Shopyxa and govern the use of the Services by you.</li>
              <li>The Terms of Service will be governed and interpreted in accordance with Indian government laws.</li>
              <li>If any provision of the Terms of Service, which include all terms and conditions and certain other documents incorporated by reference, is noticed by a court of competent jurisdiction to be against the law, such clause will be modified and analyzed in order to fulfill the goals of the original provision best as permitted by law.</li>
              <li> If any portion of a provision, in these Terms of Service is held to be illegal, or voidable in any way, such invalidity, illegality, or invalidation will not influence any other provision (or the unaffected part of the provision), and the Terms of Service will be phrased as if the such invalid, illegal, or unenforceable provision, or portion of the provision, had never been contained within the Terms of Services.</li>
              <li>All of the Terms of Service's terms and regulations will be binding on and profit the parties to the Terms of Service, as well as their respective descendants, and successors, allowed by law and legal advisors. Shopyxa will be capable of assigning these Terms of Service without your knowledge or consent. You will not be able to assign or otherwise transfer the Terms of Service, or any of your rights or obligations under them, to any third-party provider without Shopyxa's prior consent, which Shopyxa may give or withhold in its professional judgment.</li>
              <li>All related rights and responsibilities there under Terms of Service are terminated immediately upon termination, with the exception that you are still obligated to fulfill all of your responsibilities in affiliation with transactions entered into prior to revocation, as well as any obligations that accrued prior to or as a result of the termination.</li>
              <li>Any dispute arising under these terms and conditions shall be subject to the Indian laws and jurisdiction of the court of Mohali, India.</li>
            </ol>

            <h5>Eligibility</h5>
            <ol>
              <li>You must be 18 years old or older to use the Platform. The Platform may only be used or accessed by those who are legally capable of entering into and performing legally binding contracts.</li>

              <li> The Company will not be responsible if the User provides misleading information, including age. The User and/or his guardian will be exclusively responsible for the consequences under the laws of the Indian government.</li>

              <li>If You are a minor, the Company explicitly rejects all liability arising from such unauthorized use of the Platform, as well as any third-party liability arising from Your use of the Platform.</li>

            </ol>

            <h5>Shopyxa Rights</h5>
            <ol>
              <li>The Services include a variety of functions and features. All services or features will indeed be accessible to all Sellers at all times, and we are under no obligation to make any Services or features available in any jurisdiction. Except where prohibited by these Terms of Service or applicable law, we reserve the right to modify the Services or any part of them for any reason, at any time, and without notice.</li>
              <li>Abuse of any kind either verbal or written, such as threats of abuse or retribution of any Shopxa employee, representative, or supervisor will lead to instant account revocation.</li>
              <li> In case of any discordant about holding the account ownership, we have the right to investigate and ask you to prove the account ownership. The process of confirming the account ownership may include more than submitting copies of your identity proofs.  </li>
              <li>We are entitled to offer our Services to your rivals and provide no assurances of exclusivity. You also acknowledge and consent that Shopyxa employees could be Shopyxa customers or merchants and may keep competing with you, without using your confidential information.
              </li>
              <li>Our sole discretion allows us to find out the legitimate account ownership and assign the account holding rights to the rightful owner. In case, we fail to determine the rightful owner, we have the right to suspend your account until a fair decision and dispute are solved between both the concerned parties.
              </li>
            </ol>

            <h5>User Obligations</h5>
            <p>You agree and confirm with the Indian government laws and regulations while selling the products at the Shopyxa store. The terms include that:</p>
            <ol>
              <li> You are not allowed to display and share any information on your store that includes:
                <ol>
                  <li> Defamatory, obscene, harassing, provoking, sexual, derogatory, misleading,  information related to gambling, and unlawful.</li>
                  <li> Promoting harassment, racism, abuse, and unlawful activities.</li>
                  <li> Copy of others' work and information or information that violates the rights of any third party in reference to their privacy.</li>
                  <li> Any hidden pages/images or other content.</li>
                  <li> Offering selling instructions about illegal products like drugs, weapons, and all the other items that are prohibited for selling in the country.</li>
                </ol>
              </li>
              <li>You agree to not transfer your account along with all the rights/obligations associated with that account to any other third party without our prior knowledge regarding this and our written permissions.</li>
              <li>If You become aware of any action, omission, transaction, or attempted transaction that is in violation of these Terms, you must notify the Company of those infringements.</li>
              <li>You shall not make any kind of attempt to gain unauthorized access to any portion of our platform or the services we offer.</li>
              <li>You will be accountable for providing information about the products or services that You will be selling. You agree that all the information will be complete, accurate, and not misleading in any way. You must not overemphasize the attributes of such products or services so as to mislead the Users.</li>
              <li>The Company reserves the right to remove any content that it believes violates or is suspected of violating any applicable law or these Terms. In no event shall the Company accept or be liable for any content posted, or for any claims, damages, or losses resulting from the use of content.</li>

            </ol>
            <h5>Payment to Shopyxa</h5>
            <ol>
              <li>You agree that Shopyxa shall not provide you with refunds for membership.</li>
              <li>Initially your account will get a 15-day free trial.</li>
              <li>After completion of the 15 days you are subject to pay for the monthly plan(non-refundable) you select among the available plans.</li>
              <li>Shopyxa will continue to charge you the monthly payment until your account is terminated and all the outstanding amount is paid fully.</li>
              <li>You agree to pay for the monthly subscription fee in advance and will be charged after every 30 days time period.</li>
              <li> Shopyxa will continue to make 3 attempts to charge you for the monthly payments if any failure occurs. However, after 3 unsuccessful attempts, we will send you an email regarding payment failure and look for other possible ways to complete the payment.</li>
              <li>Shopyxa shall suspend your account if do not get any response from your side in a period of a month. Your account will be recommenced after receiving the pending and the next month’s payment.</li>

            </ol>

            <h5>Confidentiality</h5>
            <ol>
              <li>
                All communications between You and the Company, which include all confidential information presented to or obtained by You from the Company, as well as all information regarding the Company's business transactions with any individual with whom the Company may or may not be under a confidentiality obligation, shall be kept private by You.</li>
              <li>The company has the right to disclose confidential data to any current investor, partner, or service provider not including the platform users.</li>

            </ol>

            <h5>Indemnification</h5>
            <p>You agree to indemnify and hold us and (as applicable) our subsidiaries, associates, partners,  directors, agents, and employees,  harmless from any claim or demand, including reasonable attorneys' fees, decided to make by any third party due to or arising out of your breach of these Terms of Service or the documents it incorporates by reference; or any aspect of the transaction between you and us.
            </p>

            <h5>Cancellation Policy</h5>
            <ol>
              <li> You have the option to cancel your subscription plan at any time. If you would like to revoke your subscription plan before your subscription renewal date, you can either delete your store by logging into your store admin or email us at <a href='mailto:support@shopyxa.com'>support@shopyxa.com</a> </li>

              <li>If you choose to cancel your store subscription before the end of your plan subscription, there will be no refunds or credits given for the payment you made for your store subscription.</li>
              <li>Shopyxa reserves the right to delete all of your store content and related details at the time of termination or after the termination. Once your Subscription is canceled, your content cannot be recovered.</li>

            </ol>
            <h5>Contact Us</h5>
            <p>If you have any queries or doubts, feel free to reach us at any time at our email id: </p> 
            <p><a href='mailto:support@shopyxa.com'>support@shopyxa.com</a></p>

          </div>
        </div>
      </section>

    </main>
  )
}
