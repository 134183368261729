import React from 'react'


export default function RefundPolicy() {
  return (
    <main>

      <section className='commonPadding'>
        <div className="container">
          <div className="commonHeading text-center">
            <div className="h1">Shopyxa Refund Policy</div>
          </div>
          <div className='blogDetailContent termsPageContent'>

            <h5> Welcome to Shopyxa!</h5>
            <p>We”, “us”, “our” or “Company” shall mean Shopyxa or any of its licensees, and “you” address the Shopyxa user whether now or in the future.</p>

            <p>The platform enables entrepreneurs to create and manage online ecommerce stores to sell their products. We value your confidence in us and are dedicated to providing you with an amazing experience. Please carefully read our Refund Policy before subscribing to our platform.</p>

            <p><strong>You agree to the following refund terms specified in this policy while using Shopyxa.</strong></p>

            <h5>15-Day Free Trial</h5>
            <p>Upon signing up for Shopyxa, your account will be granted a 15-day free trial period for our professional plan. During this period, you can explore and evaluate our platform's features without any charges.</p>

            <h5>Monthly Subscription Fees</h5>
            <p> Following the completion of the 15-day free trial, you are obligated to select and pay for one of the available monthly plans. The monthly plan fee is non-refundable and will be charged in advance for every 30-day time period.</p>

            <h5>Recurring Monthly Payments</h5>
            <p>By using our platform, you agree to pay for your selected monthly subscription plan in advance, with recurring charges applied every 30 days. Shopyxa will continue to charge you an amount for the subscribed plan unless your account is terminated. </p>

            <p>The subscription fee charged for the upcoming 30 days in recurring monthly payments is non-refundable, even if you decide to terminate your account within the active plan.</p>

            <h5>Contact Us</h5>
            <p>If you have any questions or concerns about our Refund Policy or any other aspect of our platform, please feel free to contact us:</p>
            <p>Email ID: <a href='mailto:support@shopyxa.com'>support@shopyxa.com</a></p>
            <p>Phone: <a href='tel:1724005441'>+91 172 4005441</a>, <a href='tel:1724025441'> 4025441</a></p>

            <p><strong>Thank you for choosing Shopyxa as your ecommerce platform. We look forward to assisting you in launching successful online stores.</strong></p>
          </div>
        </div>
      </section>

    </main>
  )
}
