import React from 'react'
import Index from '../../../../Assets/Images/Index'
import BannerContent from '../../../Common/BannerContent/BannerContent'
import TwoCol from '../../../Common/TwoCol/TwoCol'
import TestimonialCard from '../../../Common/TestimonialCard/TestimonialCard'
import Slider from "react-slick";
import { signUpUrl } from '../../../../Container/App'
import SellingBox from '../../../Common/SellingBox/SellingBox'
import "../Sell.css"

export default function Instagram() {
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
     
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1, 
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  const startSellingwithInsta = [
    {
      img: Index.startSellingwithInsta,
      title: "Start selling on  Instagram with your Instagram Store",
      content: "Reach 1 billion Instagram users longing for products like yours with Shoppable Instagram posts from Ecwid E-commerce. Set up your Instagram shop in minutes to sell everything from clothes to antique furniture. An Instagram shop meets your customers in the same space they connect with friends, news, and favorite brands. Every day, 300-million Instagram users visit an Instagram business page after seeing a post in their feed. ",
      btnText: "Start Free Trial",
    }
  ]
  const testimonialContent = [
    {
      personImg: Index.kavitaKausal,
      name: "Kavita Kausal",
      position: "CEO at Cosmo Care",
      review: "A big idea that hints that here is something your reader really, really wants (and can get from you)."
    },
    {
      personImg: Index.pawasMahajan,
      name: "Pawas Mahajan",
      position: "CEO at Zoom Studio",
      review: "A big idea that hints that here is something your reader really, really wants (and can get from you)."
    },
    {
      personImg: Index.judieLastina,
      name: "Judie Lastina ",
      position: "CEO at Zoom Studio",
      review: "A big idea that hints that here is something your reader really, really wants (and can get from you)."
    },
    {
      personImg: Index.kavitaKausal,
      name: "Kavita Kausal",
      position: "CEO at Cosmo Care",
      review: "A big idea that hints that here is something your reader really, really wants (and can get from you)."
    },

  ]
  const sellIntaBox = [
    {
      title: "Tap for info",
      mainImg: Index.sellIntaBox1,
    },
    {
      title: "Get the details",
      mainImg: Index.sellIntaBox2,
    },
    {
      title: "Order up!",
      mainImg: Index.sellIntaBox3,
    },


  ]
  return (
    <main>
      <BannerContent
        bannerClass="sellInstaBanner"
        title="Sell your product on Instagram "
        cntnt="Shoppable Instagram Posts are just a few clicks away"
        img1={Index.sellInstaBanner}
        btnText="Start Free Trial"
      />
      <section className='testimonialSection commonPadding'>
        <div className="container">
          <div className="commonHeading text-center">
            <div className="h1">How to sell product on  Instagram </div>
          </div>
          <div className="row g-0 justify-content-center">
            <div className="col-xl-10">
              <div className="sellIntaBox__row">
                {sellIntaBox.map((data, indx) => {
                  return (
                    <div key={indx} className="sellIntaBox">
                      <div className="sellIntaBox__img homeSellBox__img">
                        <img src={data.mainImg} alt="" />
                      </div>
                      <div className="sellIntaBox__cntnt">
                        <h5><span>{indx < 9 ? "0" : null}{indx + 1}</span>{data.title}</h5>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='testimonialSection commonPadding'>
        <div className="container">
          <div className="commonHeading">
            <div className="h1">Meet the merchants who love Shopyxa </div>
          </div>
          <div className='testimonialSlider'>
            <Slider {...settings}>
              {testimonialContent.map((data, indx) => {
                return (
                  <TestimonialCard
                    key={indx}
                    personImg={data.personImg}
                    name={data.name}
                    position={data.position}
                    review={data.review}
                  />
                )
              })}
            </Slider>
          </div>
        </div>
      </section>
      <section className='twocolSect commonPadding twocolSect--insta'>
        <div className="container">
          {startSellingwithInsta.map((data, indx) => {
            return (
              <TwoCol
                key={indx}
                img={data.img}
                title={data.title}
                content={data.content}
                btnText={data.btnText}
              />
            )
          })}
        </div>
      </section>      
      <SellingBox
        title="Start Selling Online for Free"
        btnText="Start Free Trial"
        cntntPos="center" />
    </main>
  )
}
